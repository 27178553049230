import { ProductsTypes } from '../constants/action-type';

const initialState = {
    products: []
}

export const productReducer = (state=initialState, {type, payload})=>{
    switch (type) {
        case ProductsTypes.SET_PRODUCTS:
            return {...state, products: payload};
        default:
            return state;
    }
}
export const selectedProductsReducer = (state={}, {type, payload})=>{
    switch (type) {
        case ProductsTypes.SELECTED_PRODUCT:
            return {...state, ...payload};
        case ProductsTypes.REMOVE_SELECTED_PRODUCT:
            return {};
        default:
            return state;
    }
}