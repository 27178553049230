
import AddPost from '../AddPost/AddPost';
const { Component } = require("react");

class Home extends Component{
    constructor(props){
        super(props);
        console.log("[App js] constructor called");
        this.state={
          postData:[
            {
              'id':1,
              'name':'test1',
              'description':'test desc1'
            },
            {
              'id':2,
              'name':'test2',
              'description':'test desc2'
            },
            {
              'id':3,
              'name':'test3',
              'description':'test desc3'
            },
            {
              'id':4,
              'name':'test4',
              'description':'test desc4'
            }
          ],
          postTitle:'Post List',
          showPosts:true,
          count: false
        }
      }
      static getDerivedStateFromProps(props, state){
        return state;
        console.log("[App js] static derived called");
      }
    
      componentDidMount(){
        console.log("[App js] component did mount called");
      }
    
    
      togglePostHandler = () =>{
        this.setState({
          showPosts : !this.state.showPosts,
        });
      }
      onChangeTitleHandler = (id, e) =>{
        const postIndex = this.state.postData.findIndex(post => post.id=== id);
        const postData=[...this.state.postData];
        postData[postIndex].name = e.target.value;
       this.setState({
         postData,
       })
      //  console.log(this.state.postData)
      }
    render(){
        return(
            <div>
                      
                <button onClick={this.togglePostHandler} className="btn btn-primary">Show/hide</button>
                <div>
                {
                    (this.state.showPosts) ? 
                    this.state.postData.map((dat,index)=>{
                        return(<AddPost key={index} datId={dat.id} datName={dat.name} datDescription={dat.description} chnageTitle={this.onChangeTitleHandler.bind(this,dat.id)}/>)
                    })
                    : ""
                }
                
                </div>
               
            </div>)
    }
}

export default Home;