import "./invoices.css";
import React, { useEffect, useState } from 'react';
import  AxiosURL from "../../apiServices/AxiosURL";
import { INVOICES_BY_FACTORY } from "../../apiServices/ApiEndPoints";
import { QRCodeCanvas } from "qrcode.react";


const Invoices = () => {
    const [errMsg, setErrMsg]=useState("");
    const [resData, setResData]=useState([]);
    let auth = JSON.parse(localStorage.getItem("currentUser"));
    useEffect(()=>{
        getData();
    },[auth.user_id])
    const getData = async()=>{
        AxiosURL.get(INVOICES_BY_FACTORY+'/'+auth.user_id).then((res) =>{
            if(res.status === 200){
                console.log(res['data']?.data)
                setResData(res['data']?.data);
            }else if(res.status === 401){
                setErrMsg("Unauthorized");
            }
            else{
                setErrMsg("Data fetching failed")
            }
        }).catch(function (error) {
            setErrMsg(error.response.data.errors[0])
        });
    }
   
        return(
            <div className="container mt-5" >
                <div className="first-child">
                <h2> Invoices Details</h2>
                </div>
               
                <table className="table">
                    <thead>
                        <tr>
                        <th scope="col"><b>Invoice Number</b></th>
                        <th scope="col"><b>Invoice Code</b></th>
                        <th scope="col"><b>Factory</b></th>
                        <th scope="col"><b>QR-Code</b></th>
                        </tr>
                    </thead>
                    <tbody>
                    {(resData.length) ? resData.map((dataVal,index)=>{
                       return[ <tr key={dataVal.id}>
                        <th scope="row">{dataVal.invoice_number}</th>
                        <td>{dataVal.invoice_code}</td>
                        <td>{dataVal.factory_user}</td>
                        <td>
                        <QRCodeCanvas
                            id="qrCode"
                            value={dataVal.url}
                            size={100}
                            bgColor={"#fff"}
                            level={"H"}
                            />
                            </td>
                        </tr>
                       ]
                    }) : "No data found"}
                    </tbody>
                    </table>
            </div>
        )

    }

export default Invoices;