

export const LOGIN_URL = `/traceability-login`;

export const DASHBOARD_URL = `/dashboard-leaf-details`;

export const COLLECTION_POINT = `/dashboard-leaf-details-with-filter`;

export const LEAF_DETAILS_WITH_FILTER = `/dashboard-leaf-details-with-filter`;

export const GET_ALL_AGENTS = `/get-all-agents`;

export const GET_ALL_COLLECTION_POINT = `/get-all-collection-point`;

export const LEAF_GRADES = `/leaf_grades`;

export const INVOICES_BY_FACTORY = `/invoices_by_factory`;

