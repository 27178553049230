import React, {useRef, useState, useEffect} from 'react';
// import ReactMapboxGl from 'react-mapbox-gl';
import ReactMapboxGl from 'react-mapbox-gl';
import {ReactMapboxGlCluster} from 'react-mapbox-gl-cluster';
import { data } from './data';

const Map = ReactMapboxGl({
  accessToken: 'pk.eyJ1IjoiZGhhbmFuamF5a24iLCJhIjoiY2s2MGpzY3ZiMDRzbjNscWkwYm5xdjBocCJ9.u7trHsB9sMzVZCEktIe0VQ',
});

const mapProps = {
  center: [77.597130, 12.984416],
  zoom: [8],
  style: 'mapbox://styles/mapbox/streets-v8',
};


const DashboardMap = () => {

  
  const getEventHandlers = () =>{
    return {
      onClick: (properties, coords, offset) =>
        console.log(`Receive event onClick at properties: ${properties}, coords: ${coords}, offset: ${offset}`),
      onMouseEnter: (properties, coords, offset) =>
        console.log(`Receive event onMouseEnter at properties: ${properties}, coords: ${coords}, offset: ${offset}`),
      onMouseLeave: (properties, coords, offset) =>
        console.log(`Receive event onMouseLeave at properties: ${properties}, coords: ${coords}, offset: ${offset}`),
      onClusterClick: (properties, coords, offset) =>
        console.log(`Receive event onClusterClick at properties: ${properties}, coords: ${coords}, offset: ${offset}`),
    };
  }

    return (
      <div>
        <Map {...mapProps}>
          <ReactMapboxGlCluster data={data}/>
        </Map>
      </div>
    );
}




export default DashboardMap;