import AuthContext from "./context/ContextProvider";
import React,{useContext} from 'react';
import { Outlet, Navigate} from 'react-router-dom';


const ProtecteRoute = () =>{
    const {auth, setAuth} = useContext(AuthContext);
    let authToken ={'token': localStorage.getItem("token") }
    // console.log(authToken)
    return (
        !authToken.token 
        ?
        <Navigate to="/login" />
        :
        <Outlet />

    );
}

export default ProtecteRoute;