import  AxiosURL from "../../apiServices/AxiosURL";
import React,{ useState, useEffect } from 'react'
import { GET_ALL_AGENTS, LEAF_DETAILS_WITH_FILTER, GET_ALL_COLLECTION_POINT } from '../../apiServices/ApiEndPoints';
import DatePicker from "react-datepicker";

import "react-datepicker/dist/react-datepicker.css";

export default function CollectionOverv() {
    const cdate = new Date().toLocaleDateString();
    let auth = JSON.parse(localStorage.getItem("currentUser"));
    const [toDate, setToDate]=useState(new Date());
    const [fromDate, setFromDate] = useState(new Date());
    const [agent, setAgent]=useState('');
    const [cPoint, setCPoint]=useState('');
    const [errMsg, setErrMsg]=useState('');
    const [resData, setResData]=useState([]);
    const [allAgents, setAllAgents]=useState([]);
    const [collectionPoint, setCollectionPoint]=useState([]);

    useEffect(()=>{
        // console.log(collectionPoint)
        // console.log("search button was clicked",resData.agent_details)
        leaf_details_with_filter();
        agentsList();
    },[])

    const leaf_details_with_filter = () => {
        AxiosURL.get(LEAF_DETAILS_WITH_FILTER +"/"+ auth.company_id).then((res) =>{
            if(res.status == 200){
                console.log(res['data']?.data)
                setResData(res['data']?.data);
            }else if(res.status == 401){
                setErrMsg("Unauthorized");
            }
            else{
                setErrMsg("Data fetching failed")
            }
        }).catch(function (error) {
            setErrMsg(error.response.data)
            // console.log("inside",error.response.data);
        });
    }
    const agentsList =() =>{
        AxiosURL.get(GET_ALL_AGENTS +"/"+ auth.user_id).then((res) =>{
            if(res.status == 200){
                // console.log(res['data']?.data)
                setAllAgents(res['data']?.data);
            }else if(res.status == 401){
                setErrMsg("Unauthorized");
            }
            else{
                setErrMsg("Data fetching failed")
            }
        }).catch(function (error) {
            setErrMsg(error.response.data)
            // console.log("inside",error.response.data);
        });
    }
    // const allCollectionPoint = (id) =>{
    //     AxiosURL.get(GET_ALL_COLLECTION_POINT +"/"+id).then((res) =>{
    //         if(res.status == 200){
    //             // console.log(res['data']?.data)
    //             setCollectionPoint(res['data']?.data);
    //         }else if(res.status == 401){
    //             setErrMsg("Unauthorized");
    //         }
    //         else{
    //             setErrMsg("Data fetching failed")
    //         }
    //     }).catch(function (error) {
    //         setErrMsg(error.response.data)
    //         // console.log("inside",error.response.data);
    //     });
    // }
    const collectionRequest = (e) =>{
        e.preventDefault();
        let paramsVal;
        if (!agent) {
            paramsVal={'fromDate':dateFormat(fromDate,"yyyy-MM-dd"),'toDate':dateFormat(toDate,"yyyy-MM-dd")};
        }else{
            paramsVal={'fromDate':dateFormat(fromDate,"yyyy-MM-dd"),'toDate':dateFormat(toDate,"yyyy-MM-dd"),'agent_id':agent};
        }
        // setResData({"name":"test"});
        // console.log({'fromDate':dateFormat(fromDate,"MM-dd-yyyy"),'toDate':dateFormat(toDate,"MM-dd-yyyy"),'agent_id':agent})
        AxiosURL.get(LEAF_DETAILS_WITH_FILTER +"/"+ auth.company_id,{params: paramsVal}).then((res) =>{
            if(res.status == 200){
                console.log(res['data']?.data)
                setResData(res['data']?.data);
            }else if(res.status == 401){
                setErrMsg("Unauthorized");
            }
            else{
                setErrMsg("Data fetching failed")
            }
        }).catch(function (error) {
            setErrMsg(error.response.data)
        });
    }

    const dateFormat = (inputDate, format) => {
        const date = new Date(inputDate);
        const day = date.getDate();
        const month = date.getMonth() + 1;
        const year = date.getFullYear();    
        format = format.replace("MM", month.toString().padStart(2,"0"));
        if (format.indexOf("yyyy") > -1) {
            format = format.replace("yyyy", year.toString());
        } else if (format.indexOf("yy") > -1) {
            format = format.replace("yy", year.toString());
        }
        format = format.replace("dd", day.toString().padStart(2,"0"));
        return format;
    }
    
    
    return (
        <div className=" mx-auto bg-gray-200 rounded-xl shadow border p-8 m-12"> 
            <div className="row form-fields">
            <form onSubmit={collectionRequest}>
                <div className="grid gap-6 mb-6 md:grid-cols-4">
                    <div>
                        <label htmlFor="first_name" className="block mb-1 text-sm font-medium text-gray-900 dark:text-gray-300">From Date</label>
                        <DatePicker selected={fromDate} type="text" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="Select Date" name="fromDate" onChange={(date) => setFromDate(date)} required />
                    </div>
                    <div>
                        <label htmlFor="last_name" className="block mb-1 text-sm font-medium text-gray-900 dark:text-gray-300">To Date</label>
                        <DatePicker selected={toDate}  type="text" id="last_name" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"  placeholder="Select Date" name="toDate" onChange={(date) => setToDate(date)} />
                    </div>
                    <div>
                        <label htmlFor="company" className="block mb-1 text-sm font-medium text-gray-900 dark:text-gray-300">Supplied Agents</label>
                        <select value={agent} onChange={(e) => {setAgent(e.target.value)}} className="form-control">
                            <option value="0">Select Agent</option>
                                {
                                    allAgents.map((optVal,i)=>{
                                        return  <option value={optVal.id} key={i}>{optVal.tid},({optVal.first_name})</option>
                                    })
                                }
                            </select>
                    </div>
                    <div>
                        <label htmlFor="company" className="block mb-4 text-sm"></label>
                        <button type="submit" className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-8 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">Search</button>
                    </div>  
                </div>
            </form>
            </div>
            <div className="content-wrapper p-0">
                <div className="d-sm-flex justify-content-between align-items-center border-bottom">
                    <div className="d-flex align-items-center">
                    <ul className="nav nav-tabs home-tab" role="tablist">
                        <li className="nav-item">
                            <p className="nav-link active" aria-controls="Dashboards-1" >Collection Overview</p>
                        </li>
                        </ul>
                    </div>
                </div>
                <div className="tab-content home-tab-content">
                    <div className="tab-pane active" id="Dashboards-1" role="tabpanel" aria-labelledby="Dashboards-tab-1">
                    <div className="row">
                        <div className="col-md-12 grid-margin stretch-card">
                        <div className="card">
                            <div className="card-body">
                            <div className="row">
                                <div className="col-lg-6">
                                    
                            <div className="d-sm-flex justify-content-between align-items-center">
                                <h3 className="text-dark font-weight-medium">Collection Overview</h3>
                            </div>
                            <div className="mb-4">
                                <span className="pr-2">Today, ({cdate})</span>
                            </div>
                                </div>
                                <div className="col-lg-6">
                                <div className="row chart-legends-revenue-statistics">
                                    <div className="col-sm-4 mb-4 mb-sm-0">
                                    <div className="legend-label d-flex">
                                        <h5 className="mb-0 font-weight-normal">Total Requested</h5>
                                    </div>
                                    <h3 className="text-dark font-weight-medium mt-2">{resData.total_quantity} Kg</h3>
                                    </div>
                                    <div className="col-sm-4 mb-4 mb-sm-0">
                                        <div className="legend-label d-flex align-items-center">
                                        <h5 className="mb-0 font-weight-normal">Total Accepted</h5>
                                        </div>
                                        <h3 className="text-dark font-weight-medium mt-2">{(resData.accepted) ? resData.accepted :"00"} Kg</h3>
                                    </div>
                                    <div className="col-sm-4 mb-4 mb-sm-0">
                                        <div className="legend-label d-flex align-items-center">
                                        <h5 className="mb-0 font-weight-normal">Total Collected</h5>
                                        </div>
                                        <h3 className="text-dark font-weight-medium mt-2">{(resData.accepted || resData.total_quantity) ? (resData.total_quantity) :"00"} Kg</h3>
                                    </div>
                                </div>
                                </div>
                            </div>
                            <div className="row mt-3" >
                            {(resData.agent_details?.length) ? resData.agent_details?.map((dataVal,index)=>{
                              return <div className="col-sm-12" key={index}> 
                                    <div className="custom-fieldset mb-1 mt-4">
                                        <div className="clearfix">
                                            <label>{dataVal.created_at}</label>
                                        </div>
                                        <div className="row">
                                            <div className="col-lg-6">        
                                                <div className="d-sm-flex justify-content-between align-items-center">
                                                    <h4 className="card-title">Data - {index+1}</h4>
                                                </div>
                                                <div className="">
                                                    <span className="pr-2">TID: {dataVal.tid}</span>
                                                </div>
                                            </div>
                                            <div className="col-lg-6">
                                                <div className="row chart-legends-revenue-statistics">
                                                    <div className="col-sm-4 mb-4 mb-sm-0">
                                                        <div className="legend-label d-flex align-items-center">
                                                            <h5 className="mb-0 font-weight-normal">Requested</h5>
                                                        </div>
                                                        <h3 className="text-dark font-weight-medium mt-2">{(dataVal.total_quantity_group) ? dataVal.total_quantity_group : "00"} Kg </h3>
                                                    </div>
                                                    <div className="col-sm-4 mb-4 mb-sm-0">
                                                        <div className="legend-label d-flex align-items-center">
                                                        <h5 className="mb-0 font-weight-normal">Accepted</h5>
                                                        </div>
                                                        <h3 className="text-dark font-weight-medium mt-2">{(dataVal.accepted_group) ? dataVal.accepted_group : "00"} Kg</h3>
                                                    </div>
                                                    <div className="col-sm-4 mb-4 mb-sm-0">
                                                        <div className="legend-label d-flex align-items-center">
                                                        <h5 className="mb-0 font-weight-normal">Collected</h5>
                                                        </div>
                                                        <h3 className="text-dark font-weight-medium mt-2">{(dataVal.completed_group)? dataVal.completed_group : "00"} Kg</h3>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div> 
                                 }) : "No data found"}
                            </div>
                           
                            
                            </div>
                        </div>
                        </div>
                    </div>
                    </div>
                </div> 
            </div>
        </div>
    )
}
