
import { Link, useNavigate } from "react-router-dom";
import AuthContext from "../../context/ContextProvider";
import React, { useEffect, useState, useContext } from 'react';
import { ToastContainer, toast } from 'react-toastify';


// const logo = require('./assets/images/Bikram_Biswas.jpeg'); // with require


let Navbar =(props)=>{
    const {auth, setAuth} = useContext(AuthContext);
    // console.log("This data from context -- ", auth.user_id)
    
    let navigate = useNavigate();
    const [currentUser, setCurrentUser] = useState({})
    useEffect(()=>{
        if(localStorage.getItem("currentUser") != null){
            setAuth(JSON.parse(localStorage.getItem("currentUser")))
        }
    },[])
    const logOutUser = () => {
        // console.log("calling..")
        localStorage.clear();
        setAuth({})
        toast("Thank you! you were logged out.",{
            position: "bottom-left",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            });
        navigate("/login", { replace: true });
    }
    const userVal = (user) =>
    {
        if(user){
            return <Link className="navbar-brand brand-logo tarce-admin" to="/">
            <span style={{color: "#ffc107"}}></span> {user}</Link>
        }
        return <h3>User not found</h3>
    }
    return(
        <div className="container-scroller">
            <nav className="navbar col-lg-12 col-12 p-0 fixed-top d-flex flex-row">
                <div className="navbar-menu-wrapper d-flex align-items-center justify-content-end">
                <img src="/assets/images/solitrace/solitrace_logo.png" style={{ width: 150 }} />
                    {userVal(auth.first_name)}
                    { auth.company_id == "3086" ? <img src="/assets/images/Bikram_Biswas.jpeg" style={{ width: 150, height: 60 }} /> : ""}   
                    <ul className="navbar-nav navbar-nav-right">
                        <li className="nav-item count-indicator nav-profile dropdown">
                        {/* <span className="count">3</span>
                        <a className="nav-link" href="#" data-toggle="dropdown">
                            <span className="nav-profile-name">Hi, {currentUser.first_name}</span>
                            <img src="assets/images/faces/face28.png" alt="profile"/>
                        </a> */}
                        <div className="">
                            {auth?.user_id ?
                            <div>
                                <button className="btn btn-warning" aria-current="page" onClick={logOutUser}>LogOut</button> &nbsp;
                                <Link className="btn btn-primary" to='/invoice_details'>Invoice Details</Link>
                                </div>
                                :
                                <Link className="btn btn-primary" aria-current="page" to='/login'>Login</Link>
                            }
                        </div>
                        </li>
                    </ul>
                </div>
            </nav>

        {/* <nav className="navbar navbar-expand-lg navbar-light bg-light">
            <div className="container-fluid">
                <Link className="navbar-brand" to="dashboard">Soli Trace Admin</Link>
                <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
                <span className="navbar-toggler-icon"></span>
                </button>
                <div className="collapse navbar-collapse" id="navbarNav">
                <ul className="navbar-nav">
                    <li className="nav-item">
                        <Link className="nav-link active" aria-current="page" to='home'>Home</Link>
                    </li>
                    <li className="nav-item">
                        
                        {auth?.user_id ?
                            <button className="nav-link active" aria-current="page" onClick={logOutUser}>LogOut</button>
                            :
                            <Link className="nav-link active" aria-current="page" to=''>Login</Link>
                        }
                    </li>
                    
                </ul>
                </div>
            </div>
        </nav> */}

        <ToastContainer />
        </div>
    )

}
export default Navbar;