import './App.css';
import AuthContext from "./context/ContextProvider";
import React, { useState,useContext } from 'react';
import { BrowserRouter, Routes, Route, Switch } from "react-router-dom";
import Home from './components/home/Home';
import Navbar from './components/navbar/Navbar';
import Login from './components/login/Login';
import Dashboard from './components/dashboard/Dashboard';
import PrivateRoutes from "./PrivateRoutes";
import Invoices from "./components/Invoices/invoices";
import QrGenerator from "./components/QrGenerator";
import Header from "./containers/Header";
import ProductListing from "./containers/ProductListing";
import ProductDetail from "./containers/ProductDetail";

import DashboardMap from "./../src/components/maps/DashboardMap"
import StatusBar from "./../src/components/statusBar/StatusBar"


// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries


let App = () => {
  const {auth, setAuth} = useContext(AuthContext);
    return(
      <div>
          <Navbar />
          {/* <Header />  */}
          <Routes>
            <Route path="/login" element={<Login />} exact />
            <Route element={<PrivateRoutes />}>
              <Route path="/home" element={<Home />} exact />
              <Route path="/" element={<Dashboard />}  exact/>
              {/* <Route path="/" element={<QrGenerator />}  exact/> */}
              <Route path="/invoice_details" element={<Invoices />}  exact/>
              {/* <Route path="/" element={<ProductListing />}  exact/> */}
              {/* <Route path="/product-detail/:productId" element={<ProductDetail />}  exact/> */}
            </Route>

            <Route path="*" element={"404 page not found"} />
          </Routes>
      </div>)
}
export default App;



// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyBPJDZxce0ZQPoypmxLS9NPGF7FM3l3g9g",
  authDomain: "react-22e95.firebaseapp.com",
  databaseURL: "https://react-22e95-default-rtdb.firebaseio.com",
  projectId: "react-22e95",
  storageBucket: "react-22e95.appspot.com",
  messagingSenderId: "572464999399",
  appId: "1:572464999399:web:056b6dd8965af4bec32b42",
  measurementId: "G-6YLF3YDBFD"
};

// Initialize Firebase
const fireBApp = initializeApp(firebaseConfig);
const analytics = getAnalytics(fireBApp);

