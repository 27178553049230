import axios from "axios";

// let baseURI="http://13.235.138.16/api/";
let baseURI="https://backend.trinitea.org/api/";

export default axios.create({
    baseURL: baseURI,
    timeout: 5000,
    headers: {
      'Authorization': `Bearer ${localStorage.getItem('token')}`,
      'Content-Type': 'application/json'
    }
})