import React, { useEffect, useRef, useState } from "react";

import QRCodeStyling from "qr-code-styling";

const qrCode = new QRCodeStyling({
  width: 600,
  height: 600,
  image:
    "assets/images/solitrace/solitrace_logo.svg",
  dotsOptions: {
    color: "#008000",
    type: "rounded"
  },
  imageOptions: {
    crossOrigin: "anonymous",
    margin: 20
  }
});

export default function App() {
  const [url, setUrl] = useState("http://traceabletea.solidaridadasia.com/#/trace/TRINI_INVJai18_1683877343");
  const [fileExt, setFileExt] = useState("png");
  const ref = useRef(null);

  useEffect(() => {
    qrCode.append(ref.current);
  }, []);

  useEffect(() => {
    qrCode.update({
      data: url
    });
  }, [url]);


  return (
    <div className="App"><br />
    <br />
    <br />
    <br />
      <div ref={ref} />
      <hr />
      <div style={styles.inputWrapper}>
        <input value={url} style={styles.inputBox} />
      </div>
    </div>
  );
}

const styles = {
  inputWrapper: {
    marginTop:"120px",
    margin: "20px 0",
    display: "flex",
    justifyContent: "space-between",
    width: "100%"
  },
  inputBox: {
    flexGrow: 1,
    marginRight: 20
  }
};
