import Dialog from '../dialog/Dialog';
const { Component } = require("react");

class AddPost extends Component{
    render(){
        // console.log(this.props)
    
        return(
        <div className="container">
            <div className='col-12'>
                <p>{this.props.datId}</p>
                <p>{this.props.datName}</p>
                <p>{this.props.datDescription}</p>
            </div>
            <div className='flex'>
                <input type="text" onChange={this.props.chnageTitle} value={this.props.datName} className='px-5 py-1 rounded-x1 border border-gray-500'/>
            </div>
            <Dialog />
        </div>)
    }
}

export default AddPost;