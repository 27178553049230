import { DashboardTypes } from '../constants/action-type';

const initialState = {
    dashData: []
}

export const dashboardReducer = (state=initialState, {type, payload})=>{
    switch (type) {
        case DashboardTypes.DASHBOARD_DATA:
            return {...state, ...payload};
        default:
            return state;
    }
}