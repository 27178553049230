import './Dashboard.css';
import Chart from 'react-apexcharts'
import  AxiosURL from "../../apiServices/AxiosURL";
import CollectionOverv from "../dashboard/CollectionOverv";
import { DASHBOARD_URL } from "../../apiServices/ApiEndPoints";
import React, { useEffect, useState } from 'react';
import { useDispatch,useSelector } from 'react-redux';
import { setDashboard } from '../../redux/actions/dashboardActions';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
  } from 'chart.js';
  import { Bar } from 'react-chartjs-2';

  ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend
  );


  export const options = {
    indexAxis: 'y',
    elements: {
      bar: {
        borderWidth: 2,
      },
    },
    responsive: true,
    plugins: {
      legend: {
        position: 'right',
      },
      title: {
        display: true,
        text: 'Chart.js Horizontal Bar Chart',
      },
    },
  };
  
  const labels = ['January', 'February', 'March', 'April', 'May', 'June', 'July'];
  
  export const data = {
    labels,
    datasets: [
      {
        label: 'Dataset 1',
        data: [500, 400, 350, 500, 490, 600, 700, 910, 225],
        borderColor: 'rgb(255, 99, 132)',
        backgroundColor: 'rgba(255, 99, 132, 0.5)',
      },
      {
        label: 'Dataset 2',
        data: [300, 400, 250, 650, 149, 760, 870, 691, 225],
        borderColor: 'rgb(53, 162, 235)',
        backgroundColor: 'rgba(53, 162, 235, 0.5)',
      },
    ],
  };
  
const Dashboard = () =>{
    const [errMsg, setErrMsg]=useState("");

    const dispatch=useDispatch();
    let auth = JSON.parse(localStorage.getItem("currentUser"));
    useEffect(()=>{
        getData();
    },[auth.company_id])
    const getData = async()=>{
        AxiosURL.get(DASHBOARD_URL+'/'+auth.company_id).then((res) =>{
            if(res.status === 200){
                dispatch(setDashboard(res['data']?.data))
                console.log(res['data']?.data)
            }else if(res.status === 401){
                setErrMsg("Unauthorized");
            }
            else{
                setErrMsg("Data fetching failed")
            }
        }).catch(function (error) {
            setErrMsg(error.response.data.errors[0])
        });
    }
    const dashboardVal = useSelector((state)=> state.dashData)
    return(
        <div className='m-12'>
            <div className="mx-auto bg-gray-200 rounded-xl shadow border p-8 m-12">
                {errMsg}
                <div className="container">
                <div className="row">
                    <div className="col-xl-3 col-md-6 grid-margin stretch-card">
                    <div className="card card-boarder" >
                        <div className="card-body">
                        <h5 className="mb-0 font-weight-normal">Total Leaves Requested</h5>
                        {<h3 className="text-dark font-weight-medium mt-2 agents-details" >{dashboardVal.total_quantity} Kg</h3>}
                        </div>
                    </div>
                    </div>
                    <div className="col-xl-3 col-md-6 grid-margin stretch-card">
                    <div className="card card-boarder" >
                        <div className="card-body">
                        <h5 className="mb-0 font-weight-normal">Total Leaves Accepted</h5>
                        <h3 className="text-dark font-weight-medium mt-2 agents-details" >{dashboardVal.accepted} Kg</h3>
                        </div>
                    </div>
                    </div>
                    <div className="col-xl-3 col-md-6 grid-margin stretch-card">
                    <div className="card card-boarder" >
                        <div className="card-body">
                        <h5 className="mb-0 font-weight-normal">Total Leaves Collected</h5>
                        <h3 className="text-dark font-weight-medium mt-2 agents-details" >{dashboardVal.completed} Kg</h3>
                        </div>
                    </div>
                    </div>
                    <div className="col-xl-3 col-md-6 grid-margin stretch-card">
                    <div className="card card-boarder" >
                        <div className="card-body">
                        <h5 className="mb-0 font-weight-normal">Total Registered Agents</h5>
                        <h3 className="text-dark font-weight-medium mt-2 agents-details">{dashboardVal.agents} Envoy</h3>
                        </div>
                    </div>
                    </div>
                </div>
                </div>
            </div>
            <div className="container">
                <CollectionOverv />
            </div>
          
            
        </div>
        )
    }


export default Dashboard;