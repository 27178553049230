import React, { useEffect, useState, useRef, useContext } from "react";
import AuthContext from "../../context/ContextProvider";
import  AxiosURL from "../../apiServices/AxiosURL";
import { Offline, Online } from "react-detect-offline";
import { LOGIN_URL } from "../../apiServices/ApiEndPoints";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from 'react-toastify';
require('es6-promise').polyfill();

var originalFetch = require('isomorphic-fetch');
var fetch = require('fetch-retry')(originalFetch);

let Login = () =>{
    const [winWidth, setWinWidth]=useState(window.innerWidth);
    const buttonHandler = () => {
        setWinWidth(window.innerWidth)
    }
    useEffect(()=>{
        window.addEventListener('resize',buttonHandler)
        // console.log(winWidth);
    },[winWidth])


    let navigate = useNavigate();
    const {auth, setAuth} = useContext(AuthContext);
    const userRef=useRef();
    const errorRef=useRef();

    const [tid, setTid]=useState("");
    const [password, setPassword]=useState("");
    const [errMsg, setErrMsg]=useState("");
    const [success, setSuccess]=useState(false);

    useEffect(()=>{
        setErrMsg('');
        // userRef.current.focus();
        if(localStorage.getItem("currentUser") != null){
            navigate("/", { replace: true });
        }
    },[tid,password])
    
    const submitHandler = async(e)=>{
        e.preventDefault();
         try{
            const response = await AxiosURL.post(LOGIN_URL,{'tid':tid,'password':password,'is_valid':0}).then((res) =>{
                if(res.status == 200){
                    const resData = res['data'];
                    const accessToken = resData['data']?.token;
                    localStorage.setItem("currentUser",JSON.stringify(resData['data']));
                    localStorage.setItem("token",accessToken);
                    setTid('');
                    setPassword('');
                    setSuccess(true);
                    if(localStorage.getItem("currentUser") != null){
                        setAuth(JSON.parse(localStorage.getItem("currentUser")))
                    }
                    toast("Thank you, you were logged in!",{
                        position: "bottom-left",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        });
                    navigate("/", { replace: true });
                }else if(res.status == 401){
                    setErrMsg("Unauthorized");
                }
                else{
                    setErrMsg("Login Failed")
                }
            }).catch(function (error) {
                this.setAuth = 1;
                setErrMsg(error.response.data.errors[0])
                console.log("inside",error.response.data.errors[0]);
            });
         }catch(err){
            setErrMsg("Unauthorized");
             console.log(err)
         }
    }

    return(
    <div className="container-scroller">
        <div className="container-fluid page-body-wrapper full-page-wrapper">
            <div className="content-wrapper d-flex align-items-stretch auth auth-img-bg">
                <div className="row flex-grow">
                    <div className="col-lg-6 d-flex align-items-center justify-content-center">
                      <div className="auth-form-transparent text-left p-3">
                        <form onSubmit={submitHandler}>
                            <div className="brand-logo">
                                <a className="navbar-brand brand-logo tarce-admin" href="index.html">Traceability Admin</a>
                            </div>
                            <Online>You're online</Online>
                            <Offline>offline (surprise!)</Offline>
                            <h4>Factory Login!</h4>
                            <div className="form-group">
                                <label htmlFor="text">T-Id</label>
                                <input type="text" className="form-control" required ref={userRef} onChange={(e)=>setTid(e.target.value)} value={tid} placeholder="T - ID eb60b7" />
                            </div>
                            <div className="form-group">
                                <label htmlFor="password">Password</label>
                                <input type="password" className="form-control"  required onChange={(e)=>setPassword(e.target.value)} value={password} placeholder="Password 123456" />
                            </div>
                            <button type="submit" className="btn btn-primary">Login</button>
                        </form>
                        <p ref={errorRef} className={errMsg ? "text-danger" : "offscreen"} aria-live="assertive">{errMsg}</p>
                        </div>
                    </div>
                    <div className="col-lg-6 login-half-bg d-flex flex-row">
                        <p className="text-white font-weight-medium text-center flex-grow align-self-end">Copyright &copy; 2022  All rights reserved.</p>
                    </div>
                </div>
            </div>
        </div>
        <ToastContainer />
    </div>
    )
}
export default Login;