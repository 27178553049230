import { combineReducers } from "redux";
import { productReducer, selectedProductsReducer } from "./productReducer";
import { dashboardReducer } from "./dashboardReducer";


const reducers = combineReducers({
    allProducts: productReducer,
    product: selectedProductsReducer,
    dashData: dashboardReducer,
})

export default reducers;